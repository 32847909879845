import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Splash from "./Pages/Splash.jsx";
import Login from "./Pages/Login.jsx";
import Dashboard from "./Pages/Dashboard.jsx";
import Support from "./Pages/Support.jsx";
import Buoys from "./Pages/Buoys.jsx";
import Alerts from "./Pages/Alerts.jsx";
import Accounts from "./Pages/Accounts.jsx";
import Howitworks from "./Pages/Howitworks.jsx";
import Settings from "./Pages/Settings.jsx";
import { getUserData } from "./utils/Storage.js";
import AutoLogin from "./Pages/AutoLogin.jsx";

function App() {
  const loggedInUser = getUserData();
  return (
    <BrowserRouter>
      <Routes>
        {loggedInUser?.isLoggedIn ? (
          <>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/support" element={<Support />} />
            <Route path="/buoys" element={<Buoys />} />
            <Route path="/alerts" element={<Alerts />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/how-it-works" element={<Howitworks />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Splash />} />
            <Route path="/login" element={<Login />} />
            <Route path="/auth" element={<AutoLogin />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
