import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";

const MapContainer = ({ locations, onMarkerClick }) => {
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const initialCenter = {
    lat: 17.600544,
    lng: 83.613737,
  };

  const [mapCenter, setMapCenter] = useState(initialCenter);
  const [mapZoom, setMapZoom] = useState(8);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    // console.log("locations", locations);
  }, []);

  const handleMarkerClick = (location) => {
    handleMapCenter(location);
    onMarkerClick(location);
    setMapZoom(10);
    setSelectedMarker(location);
  };

  const handleMapCenter = (location) => {
    setMapCenter({
      lat: location.latitude,
      lng: location.longitude,
    });
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={mapZoom}
        center={mapCenter}
        mapContainerClassName="map"
        options={{
          draggable: true,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {locations.map((location, index) => (
          <MarkerF
            key={index}
            position={{ lat: location.latitude, lng: location.longitude }}
            onClick={() => handleMarkerClick(location)}
            icon={{
              url: "../assets/images/ripple.gif",
            }}
          >
            {selectedMarker === location && (
              <InfoWindowF onCloseClick={handleInfoWindowClose}>
                <div>
                  <h6>{location.name}</h6>
                  {/* <p>{location.unique_id}</p> */}
                  <p>
                    {location.latitude}, {location.longitude}
                  </p>
                </div>
              </InfoWindowF>
            )}
          </MarkerF>
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

MapContainer.propTypes = {
  locations: PropTypes.array,
  onMarkerClick: PropTypes.func,
};

export default MapContainer;
