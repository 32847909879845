import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MapContainer from "../components/Map";
import Chart from "../components/Chart";
import Sidebar from "../components/Sidebar";
import { getApi } from "../utils/api";
import { buoy, data, filter } from "../utils/endpoint";
import SpinnerLoader from "../components/SpinnerLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns";

function Dashboard() {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [Show, setShow] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const defaultStartDate = addDays(new Date(), -1);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(startDate);
  const [isData, setIsData] = useState("");

  // const availablePorts = [
  //   {
  //     lat: 17.600544,
  //     lng: 83.613737,
  //     id: 1,
  //     name: "Gangavaram port",
  //     description: "",
  //     key: "SPM BUOY 1256",
  //     location: "Gangavaram port",
  //     depth: "20m",
  //     horizontal_speed: "5 knots",
  //     direction: "19 Deg",
  //     time: "06:00 hrs",
  //     date: "Jan 4, 2024",
  //   },
  //   // Add more locations as needed
  // ];

  const customData = [
    {
      position: "17.600544, 83.613737",
      name: "Gangavaram port",
      description: "",
      key: "SPM BUOY 1256",
      location: "Gangavaram port",
      depth: "20m",
      horizontal_speed: "-",
      direction: "-",
      time: "-",
      date: "-",
      image: "../assets/images/graph1.png",
    },
  ];

  const [currentData, setCurrentData] = useState(customData[0]);
  const [buoyLocations, setBuoyLocations] = useState([]);
  const [currentBuoy, setCurrentBuoy] = useState(false);
  const [currentBuoyData, setCurrentBuoyData] = useState({ import_data: [] });
  const [windData, setWindData] = useState(false);
  const [formattedData7, setFormattedData7] = useState([]);
  const [formattedData20, setFormattedData20] = useState([]);
  const [formattedData40, setFormattedData40] = useState([]);
  const [formattedData60, setFormattedData60] = useState([]);
  const [checkedDepths, setCheckedDepths] = useState(["all"]);

  useEffect(() => {
    getApi(buoy)
      .then((res) => {
        if (res.data?.status) {
          setBuoyLocations(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleFilterClick = () => {
    // Get a random index from the customData array
    const randomIndex = Math.floor(Math.random() * customData.length);

    // Access the randomly selected entry
    const randomEntry = customData[randomIndex];

    // Set the currentData state to the randomly selected entry
    setCurrentData(randomEntry);

    // Add any additional filtering or processing logic if needed
    setShowFilterBox(false);
  };

  const handleMarkerClick = (location) => {
    console.log(currentData);
    handleCloseFilter();
    handleClear();
    if (location.latitude) {
      setCurrentBuoy(location);
      setWindData(false);
      setIsDetailsOpen(true);
      setIsLoadingDetails(true);
      getApi(buoy + "/" + location.id)
        .then((res) => {
          if (res.data?.status) {
            // console.log(res.data);
            setCurrentBuoyData(res.data.data);
            // console.log(currentBuoyData);
            // console.log("Import Data", currentBuoyData.import_data);
            setIsLoadingDetails(false);
            // windData(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingDetails(false);
        });
    }
  };

  const handleImportData = (event) => {
    const selectedId = event.target.value;
    // Now you can use the selectedId as needed

    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedName = selectedOption.getAttribute("name");

    setSelectedFile(selectedName);
    if (selectedId !== "") {
      setIsLoadingChart(true);
      getApi(data + "/" + selectedId)
        .then((res) => {
          if (res.data?.status) {
            // console.log("Data is here", res.data);
            if (res.data.data?.length > 0) {
              handleWindData(res.data.data);
            } else {
              setIsData("No data available.");
            }

            setIsLoadingChart(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingChart(false);
        });
    } else {
      handleClear();
    }
  };

  const handleWindData = (data) => {
    setWindData(data);

    // Update state variables using set functions
    const data7 = data.map((item) => ({
      x: new Date(item.timestamp * 1000).getTime(),
      y: item.horizontal_speed_7,
      direction: item.direction_7,
    }));

    const data20 = data.map((item) => ({
      x: new Date(item.timestamp * 1000).getTime(),
      y: item.horizontal_speed_20,
      direction: item.direction_20,
    }));

    const data40 = data.map((item) => ({
      x: new Date(item.timestamp * 1000).getTime(),
      y: item.horizontal_speed_40,
      direction: item.direction_40,
    }));

    const data60 = data.map((item) => ({
      x: new Date(item.timestamp * 1000).getTime(),
      y: item.horizontal_speed_60,
      direction: item.direction_60,
    }));
    if (checkedDepths.length > 0 && !checkedDepths.includes("all")) {
      if (checkedDepths.includes("4m")) {
        //it is 7m  , just for view it is 4m
        setFormattedData7(data7);
      }
      if (checkedDepths.includes("20m")) {
        setFormattedData20(data20);
      }
      if (checkedDepths.includes("40m")) {
        setFormattedData40(data40);
      }
      if (checkedDepths.includes("60m")) {
        setFormattedData60(data60);
      }
    } else {
      setFormattedData7(data7);
      setFormattedData20(data20);
      setFormattedData40(data40);
      setFormattedData60(data60);
    }
  };

  const handleClose = () => {
    setIsDetailsOpen(false);
    setWindData(false);
    handleClear();
    setShow(false);
    setIsData("");
  };

  const handleApply = () => {
    if (startDate && endDate) {
      // Convert the selected date to Epoch timestamp in GMT
      const epochTimestampGMTStartDate =
        (startDate.getTime() - startDate.getTimezoneOffset() * 60000) / 1000;
      const epochTimestampGMTEndDate =
        (endDate.getTime() - endDate.getTimezoneOffset() * 60000) / 1000;

      setIsLoadingChart(true);
      getApi(
        filter +
          `?start=${epochTimestampGMTStartDate}&end=${epochTimestampGMTEndDate}`
      ) // prettier-ignore
        .then((res) => {
          if (res.data?.status) {
            // console.log("Data is here", res.data);
            // setWindData(res.data.data);
            if (res.data.data?.length > 0) {
              handleWindData(res.data.data);
            } else {
              setIsData("No data available.");
            }
            setIsLoadingChart(false);
            setIsApply(true);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingChart(false);
        });
    }
  };

  const handleClear = () => {
    setIsApply(false);
    setWindData(false);
    setFormattedData7([]);
    setFormattedData20([]);
    setFormattedData40([]);
    setFormattedData60([]);
    setCheckedDepths(["all"]);
    setStartDate(defaultStartDate);
    setEndDate(new Date());
    setIsData("");
  };

  const handleCloseFilter = () => {
    setShow(false);
    setIsApply(false);
  };

  // // Function to handle checkbox change event
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    // If checked, add the value to the checkedDepths array, else remove it
    if (value !== "all") {
      let filterData = checkedDepths.filter((item) => item !== "all");
      if (checked) {
        setCheckedDepths([...filterData, value]);
      } else {
        setCheckedDepths(filterData.filter((depth) => depth !== value));
      }
    } else {
      setCheckedDepths(["all"]);
    }
  };

  const handleFilter = () => {
    setShow(true);
    handleClear();
  };

  const handlePrint = () => {
    var printContents = document.getElementById("contentToPrint").innerHTML;
    var newWindow = window.open("", "_blank");

    var header_str =
      "<html><head><title>" +
      document.title +
      "</title></head><body onload='window.print(); window.close();'>";
    let dummyTextHeader = "";
    if (isApply) {
      dummyTextHeader = `<div style="display:list-item;width:100%;justify-content:space-between;">
      <div style="padding-bottom:7px"><b>Buoy Name :</b> ${
        currentBuoy.name
      }</div>
      <div style="padding-bottom:7px">
      <b>Latitude :</b> ${currentBuoy.latitude}
      <span style="padding-left:7px"> <b>Longitude :</b> ${
        currentBuoy.longitude
      } </spna>
     </div>
     <div style="padding-bottom:7px">
     <b>Start Date & Time :</b> ${format(startDate, "dd/MM/yyyy hh:mm a")}
     <span style="padding-left:7px"> <b>End Date & Time :</b>
     ${format(endDate, "dd/MM/yyyy hh:mm a")} </spna>
    </div>
    <div style="padding-bottom:7px"><b>Depth:</b>   ${
      checkedDepths && checkedDepths.map((item) => item + " ")
    }</div>
   </div><br>`;
    } else {
      dummyTextHeader = `<div style="display:list-item;width:100%;justify-content:space-between;">
      <div style="padding-bottom:7px"><b>Buoy Name :</b> ${currentBuoy.name}</div>
      <div style="padding-bottom:7px">
     <b>Latitude :</b> ${currentBuoy.latitude}
    <span style="padding-left:7px"> <b>Longitude :</b> ${currentBuoy.longitude} </spna>
      </div>
      <div  style="padding-bottom:7px"><b>Selected File :</b>${selectedFile}</div>
      </div><br><div style="width:100% !important">`;
    }

    var footer_str = "</div></body></html>";
    var htmlContent = header_str + dummyTextHeader + printContents + footer_str;
    newWindow.document.write(htmlContent);
    newWindow.document.close();
    // Listen for beforeunload event
    window.onbeforeunload = function () {
      // Redirect to previous window
      window.location.href = document.referrer;
    };
  };

  // Calculate the maxDate 5 days after the startDate
  const maxDate = new Date(startDate);
  maxDate.setDate(maxDate.getDate() + 5);

  return (
    <div className={isDetailsOpen ? "wrapper wrapper-clicked" : "wrapper"}>
      <div className="wrapper__left">
        <MapContainer
          locations={buoyLocations}
          onMarkerClick={handleMarkerClick}
        />

        <div className="filters d-none" id="filters">
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filter-collapse"
              aria-expanded="false"
              aria-controls="filter-collapse"
              onClick={() => setShowFilterBox(!showFilterBox)}
            >
              Filters
            </button>

            <div
              id="filter-collapse"
              className={`accordion-collapse collapse ${
                showFilterBox ? "show" : ""
              }`}
              aria-labelledby="flush-headingOne"
              data-bs-parent="#filters"
            >
              <div className="accordion-body">
                <p>Date range</p>
                <input
                  type="date"
                  className="form-control"
                  placeholder="dd-mm-yyyy"
                  required
                />
                <div className="date">
                  <label htmlFor="fromDate">From</label>
                  <input
                    type="date"
                    name="fromDate"
                    className="form-control"
                    placeholder="dd-mm-yyyy"
                    required
                  />
                </div>
                <div className="date">
                  <label htmlFor="toDate">To</label>
                  <input
                    type="date"
                    name="toDate"
                    className="form-control"
                    placeholder="dd-mm-yyyy"
                    required
                  />
                </div>
                <p className="time-ing">Time range</p>
                <input
                  type="time"
                  className="form-control"
                  placeholder="-:-"
                  required
                />
                <div className="date">
                  <label htmlFor="fromTime">From</label>
                  <input
                    type="time"
                    className="form-control"
                    name="fromTime"
                    placeholder="dd-mm-yyyy"
                    required
                  />
                </div>
                <div className="date">
                  <label htmlFor="toTime">To</label>
                  <input
                    type="time"
                    className="form-control"
                    name="toTime"
                    placeholder="dd-mm-yyyy"
                    required
                  />
                </div>
                <p className="time-ing">Depth</p>
                <div className="depth">
                  <div className="item">
                    <label>
                      <input type="checkbox" value="0m" />
                      <span>0m</span>
                    </label>
                  </div>
                  <div className="item">
                    <label>
                      <input type="checkbox" value="20m" />
                      <span>20m</span>
                    </label>
                  </div>
                  <div className="item">
                    <label>
                      <input type="checkbox" value="40m" />
                      <span>40m</span>
                    </label>
                  </div>
                  <div className="item">
                    <label>
                      <input type="checkbox" value="60m" />
                      <span>60m</span>
                    </label>
                  </div>
                  <div className="item">
                    <label>
                      <input type="checkbox" value="All" />
                      <span>All</span>
                    </label>
                  </div>
                </div>
                <div className="button-click-apply">
                  <button
                    type="submit"
                    className="apply"
                    onClick={() => handleFilterClick()}
                  >
                    Apply
                  </button>
                  <button type="submit" className="apply clear">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidebar />
        <div className="searching">
          <img
            src="../assets/images/searching.svg"
            alt=""
            width={52}
            height={52}
            className="icon"
          />
          <input type="search" placeholder="Search BUOY" />
        </div>
        <div className="compass">
          <img src="../assets/images/compass.svg" alt="compass" />
        </div>
      </div>

      <div className="analytics">
        {!isLoadingDetails ? (
          <>
            <h4 className="d-flex">
              {currentBuoy.name}
              <Link
                className="ms-auto text-decoration-none text-danger"
                onClick={handleClose}
              >
                <small>Close</small>
              </Link>
            </h4>
            <h6>
              {currentBuoy.latitude}, {currentBuoy.longitude} &nbsp;
              {/* <span>( {currentBuoy.unique_id} )</span> */}
            </h6>
            <hr />
            <div className="p-g d-block d-md-none">
              {isApply && (
                <a href="#" onClick={handleClear}>
                  Clear
                </a>
              )}
              {windData && (
                <>
                  <button className="print-graphs" onClick={handlePrint}>
                    <i className="fa fa-print" aria-hidden="true"></i>Print
                  </button>
                </>
              )}
            </div>
            <div className="file-list">
              {!Show && (
                <>
                  <div className="input-select-listing">
                    <label>Select File</label>
                    <select
                      onChange={handleImportData}
                      className="form-control form-select"
                    >
                      <option value="">Select</option>
                      {currentBuoyData.import_data.map((data, index) => (
                        <option key={index} value={data.id} name={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {/* <div className="map-filters"> */}
              <div className="map-show">
                {!Show && !isApply && (
                  <Link to="#" className="filters-click" onClick={handleFilter}>
                    <img
                      src="../assets/images/bars-filter.svg"
                      alt=""
                      style={{ width: "20px," }}
                    />
                    Filters
                  </Link>
                )}

                {isApply && (
                  <>
                    <div className="option-map-start-end">
                      <div>
                        <label htmlFor="startDate">Start Date & Time</label>
                        <p>{format(startDate, "dd/MM/yyyy hh:mm a")}</p>
                      </div>
                      <div className="d-none d-md-block">
                        <label htmlFor="endDate">End Date & Time</label>
                        <p>{format(endDate, "dd/MM/yyyy hh:mm a")} </p>
                      </div>
                      <div>
                        <label htmlFor="Depth">Depth</label>
                        <p>
                          {
                            checkedDepths && 
                            checkedDepths.map(
                              (item, index) =>
                              index === checkedDepths.length -1
                                ? item + " "
                                : item + ", " 
                            ) //prettier-ignore
                          }
                        </p>
                      </div>
                      <button
                        type="button"
                        className="clear-d-t d-none d-md-block"
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                      <div className="d-block d-md-none ">
                        <label htmlFor="endDate">Start Date & Time</label>
                        <p>02/27/2024, 11:54 AM</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {Show && !isApply ? (
                <form action="" className="map-form">
                  <div className="accordion-body">
                    <div className="ac-time">
                      <div className="date first-date">
                        <label htmlFor="fromDate">Start Date & Time</label>
                        <DatePicker
                          selected={startDate}
                          className="form-control"
                          onChange={(date) => setStartDate(date)}
                          showTimeSelect
                          dateFormat="Pp"
                          required
                        />
                      </div>
                      <div className="date last-date">
                        <label htmlFor="fromDate">End Date & Time</label>
                        <DatePicker
                          minDate={startDate}
                          maxDate={maxDate}
                          selected={endDate}
                          className="form-control end-date-tt"
                          onChange={(date) => setEndDate(date)}
                          showTimeSelect
                          required
                          dateFormat="Pp"
                        />
                      </div>
                    </div>
                    <p className="time-ing">Depth</p>
                    <div className="depth">
                      <div className="item">
                        <label>
                          <input
                            type="checkbox"
                            value="4m"
                            name="depth"
                            required
                            onChange={handleCheckboxChange}
                            checked={checkedDepths.includes("4m")}
                          />
                          <span>4m</span>
                        </label>
                      </div>
                      <div className="item">
                        <label>
                          <input
                            type="checkbox"
                            value="20m"
                            name="depth"
                            onChange={handleCheckboxChange}
                            checked={checkedDepths.includes("20m")}
                          />
                          <span>20m</span>
                        </label>
                      </div>
                      <div className="item">
                        <label>
                          <input
                            type="checkbox"
                            value="40m"
                            name="depth"
                            onChange={handleCheckboxChange}
                            checked={checkedDepths.includes("40m")}
                          />
                          <span>40m</span>
                        </label>
                      </div>
                      <div className="item">
                        <label>
                          <input
                            type="checkbox"
                            value="60m"
                            name="depth"
                            onChange={handleCheckboxChange}
                            checked={checkedDepths.includes("60m")}
                          />
                          <span>60m</span>
                        </label>
                      </div>
                      <div className="item">
                        <label>
                          <input
                            type="checkbox"
                            value="all"
                            name="depth"
                            onChange={handleCheckboxChange}
                            checked={checkedDepths.includes("all")}
                          />
                          <span>All</span>
                        </label>
                      </div>
                    </div>
                    <div className="button-click-apply">
                      <button
                        type="button"
                        className="apply"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="apply clear"
                        onClick={handleCloseFilter}
                      >
                        Close Filters
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}
              {/* Button to trigger print */}
              {windData && (
                <button
                  className="print-graphs d-none d-md-block"
                  onClick={handlePrint}
                >
                  <i className="fa fa-print" aria-hidden="true"></i>Print
                </button>
              )}
            </div>
            {/* </div> */}

            <div className="graphs">
              {isLoadingChart ? (
                <SpinnerLoader />
              ) : (
                <>
                  {windData ? (
                    <>
                      <div id="contentToPrint">
                        {isApply && (
                          <>
                            {(checkedDepths.includes("4m") ||
                              checkedDepths.includes("all")) && (
                              <Chart data={formattedData7} title="Depth 4m" />
                            )}
                            {(checkedDepths.includes("20m") ||
                              checkedDepths.includes("all")) && (
                              <Chart data={formattedData20} title="Depth 20m" />
                            )}
                            {(checkedDepths.includes("40m") ||
                              checkedDepths.includes("all")) && (
                              <Chart data={formattedData40} title="Depth 40m" />
                            )}
                            {(checkedDepths.includes("60m") ||
                              checkedDepths.includes("all")) && (
                              <Chart data={formattedData60} title="Depth 60m" />
                            )}
                          </>
                        )}
                        {!isApply && (
                          <>
                            <Chart data={formattedData7} title="Depth 4m" />
                            <Chart data={formattedData20} title="Depth 20m" />
                            <Chart data={formattedData40} title="Depth 40m" />
                            <Chart data={formattedData60} title="Depth 60m" />
                          </>
                        )}
                      </div>
                    </>
                  ) : null}
                  {isData && (
                    <p className="text-center m-5 text-secondary">{isData}</p>
                  )}
                  {(!windData || currentBuoyData?.import_data.length === 0) &&
                    !Show && (
                      <p className="text-center m-5 text-secondary">
                        No data available.
                      </p>
                    )}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <SpinnerLoader />
          </>
        )}
      </div>
      {/* Print styles */}
      {/* <style>{printStyles}</style> */}
    </div>
  );
}

export default Dashboard;
