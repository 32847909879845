import React from "react";
import Sidebar from "../components/Sidebar";

const Settings = () => {
  return (
    <>
      <Sidebar />
      <div className="">
        <div className="container">
          <div className="row">
            <h3 className="heading">Settings</h3>
            <p className="text-center">Coming Soon</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
