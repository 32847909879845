import { EncryptStorage } from "encrypt-storage";

export const encryptStorage = new EncryptStorage( 
  process.env.REACT_APP_STORAGE_ENCRYPTION_KEY,
  {
    prefix: "@navalnetweb",
  } // prettier-ignore
); // prettier-ignore

export const setUserData = (data) => {
  encryptStorage.setItem("nnIsLoggedIn", true);
  encryptStorage.setItem("nnUser", JSON.stringify(data.data.user));
  encryptStorage.setItem("nnToken", data.data.token, true);
};

export function getUserData() {
  let user = {
    isLoggedIn: encryptStorage.getItem("nnIsLoggedIn"),
    user: encryptStorage.getItem("nnUser"),
    token: encryptStorage.getItem("nnToken", true),
  };
  return user;
}

export function logoutUser() {
  encryptStorage.removeItem("nnIsLoggedIn");
  encryptStorage.removeItem("nnUser");
  encryptStorage.removeItem("nnToken");
  window.location = "/login";
}

export function setLocalDataObject(key, data) {
  encryptStorage.setItem(key, JSON.stringify(data));
}

export function getLocalDataObject(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function deleteLocalDataObject(key) {
  encryptStorage.removeItem(key);
}

export function setLocalData(key, data) {
  encryptStorage.setItem(key, data);
}

export function getLocalData(key) {
  return encryptStorage.getItem(key);
}

export function deleteLocalData(key) {
  encryptStorage.removeItem(key);
}
