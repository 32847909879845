import React from "react";
import Highcharts from "highcharts";
import PropTypes from "prop-types";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/windbarb")(Highcharts);

const Chart = ({ data, title }) => {
  const options = {
    credits: {
      enabled: false, // Disables the Highcharts.com credit
    },
    chart: {
      height: 250,
      spacingBottom: 30,
    },

    title: {
      text: title,
      align: "left",
    },

    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        hour: "%H:%M",
      },
      title: {
        text: "Timestamp",
      },
      // offset: 40,
    },

    series: [
      {
        type: "windbarb",
        data: data,
        // data: data.map((point) => ({
        //   x: point.x,
        //   value: point.y,
        //   defaultDirection: point.direction, // Wind direction
        //   direction: point.y, // Wind speed
        // })),
        name: "Current speed",
        color: Highcharts.getOptions().colors[1],
        showInLegend: false,
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name} Depth</br>Speed: <b>{point.value}</b> cm/sec<br/>Direction: <b>{point.defaultDirection} deg</b>',
        },
      },
      {
        type: "area",
        keys: ["y"], // wind direction is not used here
        data: data,
        color: Highcharts.getOptions().colors[0],
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0.25)
                .get(),
            ],
          ],
        },
        name: "Current speed",
        showInLegend: false,
        tooltip: {
          // valueSuffix: " knots",
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name} Depth</br>Speed: <b>{point.y}</b> cm/sec<br/>Direction: <b>{point.direction} deg</b>',
        },

        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Chart;

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
