import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "../utils/Storage";

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setIsOpen(false);
      } else {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  return (
    <>
      <div className={isOpen ? "sidebar " : "sidebar sidebar-remove"}>
        <label
          htmlFor="check"
          className="check-box"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src="../assets/images/slider.svg" alt="" />
          {isOpen && (
            <img
              src="../assets/images/slider_arrow.svg"
              alt=""
              className="right-arrow"
            />
          )}
          {!isOpen && (
            <img
              src="../assets/images/slider_arrow_right.svg"
              alt=""
              className="right-arrow"
            />
          )}
        </label>
        <div className="menu">
          <h3>
            SPM<span>BUOY</span>
          </h3>
          <hr className="my-2 line" />
          <ul>
            <li>
              {activeItem === "/dashboard" ? (
                <Link to="/dashboard">
                  <img
                    src="../assets/images/home_icon_active.svg"
                    alt="home_icon"
                  />
                </Link>
              ) : (
                <Link to="/dashboard">
                  <img src="../assets/images/home_icon.svg" alt="home_icon" />
                </Link>
              )}

              <Link
                to="/dashboard"
                className={
                  activeItem === "/dashboard" ? "link_list active" : "link_list"
                }
              >
                Dashboard
              </Link>
            </li>
            <li>
              {activeItem === "/how-it-works" ? (
                <Link to="/how-it-works">
                  <img
                    src="../assets/images/help_icon_active.svg"
                    alt="help_icon"
                  />
                </Link>
              ) : (
                <Link to="/how-it-works">
                  <img src="../assets/images/help_icon.svg" alt="help_icon" />
                </Link>
              )}

              <Link
                to="/how-it-works"
                className={
                  activeItem === "/how-it-works"
                    ? "link_list active"
                    : "link_list"
                }
              >
                How it works
              </Link>
            </li>
            <li>
              {activeItem === "/support" ? (
                <Link to="/support">
                  <img
                    src="../assets/images/support_icon_active.svg"
                    alt="support_icon"
                  />
                </Link>
              ) : (
                <Link to="/support">
                  <img
                    src="../assets/images/support_icon.svg"
                    alt="support_icon"
                  />
                </Link>
              )}
              <Link
                to="/support"
                className={
                  activeItem === "/support" ? "link_list active" : "link_list"
                }
              >
                Support
              </Link>
            </li>
            <li>
              <Link to="#" onClick={logoutUser}>
                <img
                  src="../assets/images/logout.svg"
                  alt=""
                  width={16}
                  height={16}
                />
              </Link>
              <Link onClick={logoutUser} className="link_list">
                Logout
              </Link>
            </li>
          </ul>
        </div>
        <div className="bottom-bar">
          <Link to="https://www.joinindiannavy.gov.in/" target="_blank">
            <img
              src="../assets/images/navy_logo.png"
              alt="navy_logo"
              height={100}
            />
          </Link>
          <img src="../assets/images/dux_logo_nav.svg" alt="dux_logo" />
          <p>Powered By MarinElixirs</p>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
