import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login } from "../utils/endpoint";
import { postApi } from "../utils/api";
import { setUserData } from "../utils/Storage";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ errorText: "", isError: false });

  const handleLogin = () => {
    setError({ errorText: "", isError: false });
    if (email !== "" && password !== "") {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);
      postApi(login, formdata)
        .then((res) => {
          if (res.data?.status) {
            setUserData(res.data);
            setError({ errorText: "", isError: false });
            setEmail("");
            setPassword("");
            window.location = "/dashboard";
          } else {
            setError({ errorText: res.data?.message, isError: true });
          }
        })
        .catch((e) => {
          console.log(e);
          setError({ errorText: e?.response?.data?.message, isError: true });
        });
    } else {
      setError({ errorText: "Fields can't be empty", isError: true });
    }
  };

  return (
    <>
      <div className="login">
        <div className="login__left">
          <div className="login__left_user">
            <div className="login__left_navy">
              <Link to="https://www.joinindiannavy.gov.in/" target="_blank">
                <img src="../assets/images/navy_logo.png" alt="" height={100} />
              </Link>
              <p>SPECIAL PURPOSE MOORING BUOY</p>
              <p>Client: MOD - Indian Navy</p>
              <p>ATVP Project No.1/T/739/BUOY dated 22nd Dec 2022</p>
            </div>
            <img
              src="../assets/images/dux_logo.svg"
              alt=""
              width={300}
              height={200}
            />
            <p className="special-text cha-3">Contractors</p>
          </div>
          <div className="powered d-none d-md-block mt-auto">
            <p>Powered By MarinElixirs</p>
            <img src="../assets/images/MarinElixirs_logo.png" alt="" />
          </div>
        </div>

        <form action="" className="login-form">
          <h2>Login</h2>
          <input
            type="text"
            className="form-control"
            placeholder="Username"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="text-center text-danger">
            {error.isError && error.errorText}
          </span>
          <Link onClick={handleLogin}>Login</Link>
          <div className="powered mt-auto text-center w-100">
            <p className="d-block d-md-none">Powered By MarinElixirs</p>
            <img
              src="../assets/images/MarinElixirs_logo.png"
              alt="MarinElixirs_logo"
              className="d-block d-md-none"
            />
            <p>
              COPYRIGHT ©2024 DUX INFRA & ENGINEERING SOLUTIONS (P) LTD - ALL
              RIGHTS RESERVED
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
