import React from "react";
import Sidebar from "../components/Sidebar";
// import PDFViewer from "../components/PdfViewer";
import ReactPDFViewer from "../components/ReactPDFViewer";

const Howitworks = () => {
  return (
    <>
      <Sidebar />
      <div className="">
        <div className="container">
          <div className="row">
            <h3 className="heading">How it works</h3>
            <div className="text-center">
              {/* <PDFViewer /> */}
              <ReactPDFViewer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Howitworks;
