import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Splash = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/login");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <>
      <div className="splash">
        <div className="logo">
          <div className="text-center">
            <Link to="https://www.joinindiannavy.gov.in/">
              <img src="../assets/images/navy_logo.png" alt="" height={100} />
            </Link>
            <p>SPECIAL PURPOSE MOORING BUOY</p>
            <p>Client: MOD - Indian Navy</p>
            <p>ATVP Project No.1/T/739/BUOY dated 22nd Dec 2022</p>
          </div>
          <img
            src="../assets/images/dux_logo.svg"
            alt=""
            width={381}
            height={272}
            className="dux_logo"
          />
          <p className="contractor">Contractors</p>
        </div>
      </div>
    </>
  );
};

export default Splash;
