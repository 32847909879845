import React from "react";
import Sidebar from "../components/Sidebar";

const Buoys = () => {
  return (
    <>
      <Sidebar />
      <div className="">
        <div className="container">
          <div className="row">
            <h3 className="heading">BUOYs</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Buoys;
