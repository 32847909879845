import React, { useEffect } from "react";
import { postApi } from "../utils/api";
import { setUserData } from "../utils/Storage";
import { autoLogin } from "../utils/endpoint";

const AutoLogin = () => {
  useEffect(() => {
    // Function to extract token from URL parameters
    const extractTokenFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromURL = urlParams.get("token");

      // Check if a token is present in the URL
      if (tokenFromURL) {
        console.log(tokenFromURL);
        const formdata = new FormData();
        formdata.append("token", tokenFromURL);
        postApi(autoLogin, formdata)
          .then((res) => {
            if (res.data?.status) {
              setUserData(res.data);
              window.location = "/dashboard";
            } else {
              alert(res.data?.message);
            }
          })
          .catch((e) => {
            console.log(e);
            alert(e?.response?.data?.message);
          });
      }
    };

    // Call the function when the component mounts
    extractTokenFromURL();
  }, []); // Empty dependency array ensures the effect runs only once

  return <></>;
};

export default AutoLogin;
