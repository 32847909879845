import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const Support = () => {
  return (
    <>
      <Sidebar />
      <div className="support">
        <div className="container-fluid">
          <h3 className="heading">Support</h3>
          <div className="user-id">
            <div className="row">
              <p>You could reach us at -</p>
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="number-mail">
                  <h5>+91-789-379-4998</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="number-mail">
                  <Link to="mailto:duxinfrasolutions@gmail.com">
                    duxinfrasolutions@gmail.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
