import React from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { pdfjs } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MyPDFViewer = () => {
  const pdfUrl =
    process.env.REACT_APP_URL + "/assets/Naval_Net_Admin_Software_Guide_V1.pdf";
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />;
};

export default MyPDFViewer;
