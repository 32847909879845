// .env API URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// API endpoints
export const login = "/login";
export const autoLogin = "/autoLogin";

export const buoy = "/buoy";
export const data = "/data";
export const filter = "/search";
